var render = function () {
  var _vm$entity, _vm$entity2, _vm$entity3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col mb-md-0 mb-3"
  }, [_c('div', {}, [_c('p', {
    staticClass: "mb-2"
  }, [_vm.categoryRoute ? _c('router-link', {
    staticClass: "text-uppercase text-muted font-w600",
    attrs: {
      "to": _vm.categoryRoute
    }
  }, [_c('i', {
    class: "fa ".concat(_vm.categoryLogo)
  }), _vm._v(" " + _vm._s(_vm.category))]) : _c('span', {
    staticClass: "text-uppercase text-muted font-w600"
  }, [_c('i', {
    class: "fa ".concat(_vm.categoryLogo)
  }), _vm._v(" " + _vm._s(_vm.category))])], 1), _c('h1', {
    staticClass: "mb-0"
  }, [_vm._t("default", [_vm._v(_vm._s(_vm.title))])], 2), _vm.subTitle ? _c('p', {
    staticClass: "text-size-h2 font-w500 mb-0"
  }, [_vm._v(_vm._s(_vm.subTitle))]) : _vm._e()])]), _c('div', {
    staticClass: "col-xxl-3 col-xl-4 col-md-6 text-left"
  }, [_vm._t("actions", [(_vm$entity = _vm.entity) !== null && _vm$entity !== void 0 && _vm$entity.legalName ? _c('div', {
    staticClass: "bg-lighter p-4 rounded-md h-100"
  }, [_c('div', {
    staticClass: "text-muted"
  }, [_vm._m(0), _c('div', [_vm._v(" " + _vm._s((_vm$entity2 = _vm.entity) === null || _vm$entity2 === void 0 ? void 0 : _vm$entity2.legalName) + " "), (_vm$entity3 = _vm.entity) !== null && _vm$entity3 !== void 0 && _vm$entity3.parentEntity ? _c('span', [_vm._v("(" + _vm._s(_vm.entity.parentEntity.legalName) + ")")]) : _vm._e()])])]) : _vm._e()])], 2)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font-w600"
  }, [_c('i', {
    staticClass: "fa fa-sitemap mr-1"
  }), _vm._v(" Entity")]);
}]

export { render, staticRenderFns }